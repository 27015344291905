@import '../../variables'

.pf-details
    display: flex

    .pf-details-section:not(:first-child)
        margin-top: 30px

    .pf-details-label
        color: $gold

    .pf-details-item
        color: $white
        font-size: 1.5rem
    
    .loading-wrapper
        width: 100%
        height: calc(100vh - #{$navbar-height})

@media screen and (min-width: $md-screen + 1)
    .pf-details
        .left-half
            height: 92vh
            background-size: cover
            background-position: center
            position: sticky
            top: 8vh
            width: 50vw
            margin: 0

        .right-half
            // width: 50%
            width: 50vw
            padding-right: 25px

@media screen and (max-width: $md-screen)
    .pf-details
        .left-half
            margin: 0
            display: flex
            justify-content: center
            align-items: center

        .right-half
            width: 100%
            vertical-align: middle
            margin: auto

@media screen and (max-width: $md-screen)
    .pf-details
        .left-half
            max-width: 93vw
            margin: auto
        
