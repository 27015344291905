@import '../../variables'
.pf-box
    transform: translate3d(0, 0, 0)
    background-color: rgba($charcoal, 0.5)
    width: 100%
    padding: 5px
    border: 2px solid $secondary
    &:before, &:after
        content: "•"
        position: absolute
        width: 14px
        height: 14px
        font-size: 14px
        color: $secondary
        border: 2px solid $secondary
        line-height: 12px
        top: 5px
        text-align: center

    &:before
        left: 5px

    &:after
        right: 5px

    .pf-box-inner
        position: relative
        border: 2px solid $secondary
        padding: 30px
        &:before, &:after
            content: "•"
            position: absolute
            width: 14px
            height: 14px
            font-size: 14px
            color: $secondary
            border: 2px solid $secondary
            line-height: 12px
            bottom: -2px
            text-align: center

        &:before
            left: -2px

        &:after
            right: -2px

    .title
        text-align: center