@import '../../../variables'

.carousel-wrapper
    max-width: 100vw
    padding: 0 20px

    .thumb
        max-height: 60px

    .slide
        img
            object-fit: cover
            max-width: 50vw
            max-height: 400px


@media screen and (max-width: $sm-screen)
    // Hacky way to fix property details page woes
    .carousel-wrapper
        padding-left: 0
        padding-right: 20px