@import '../../variables'
.pf-property
    figure, img
        height: 350px
    img
        width: 100%
        object-fit: cover

    .card-content
        text-align: center
        color: $secondary
    .help
        font-size: 1rem

    .darken
        background-color: black
        img
            opacity: 0.4
    
    .overlay-text
        position: absolute
        top: 35%
        left: 50%
        transform: translate(-50%, -50%)
        color: $white
        z-index: 10
        font-size: 2rem

    .is-pending
        color: #444