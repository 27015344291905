@import '../../variables'
.pf-contact
    width: 75%
    .pf-confirmation
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        height: calc(100vh - #{$navbar-height})

    .address
        margin-top: 15px

@media screen and (max-width: $md-screen)
    .field-group:not(:last-child)
        margin-bottom: 0.75rem
    .pf-contact
        width: 92vw
