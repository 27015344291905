@import '../../variables'
@import '../../../node_modules/bulma/sass/utilities/mixins'

.app-container
    min-height: 100vh
    display: flex
    flex-direction: column

.container,
.app-content
    padding: 25px
    color: $white

.content .title
    display: flex
    justify-content: center
    align-items: center

.currency::before
    content: '$'

.loading-wrapper
    position: relative
    pointer-events: none
    opacity: 0.5
    width: 100%
    &:after
        @include loader
        border: 2px solid $secondary
        border-top-color: transparent
        border-right-color: transparent
        position: absolute
        top: calc(50% - 2.5em)
        left: calc(50% - 2.5em)
        width: 5em
        height: 5em
        border-width: 0.25em
