/* Target tablet and up */
@media screen and (min-width: $md-screen)
    .field-group
        display: flex

        /* Give margin on left if not first field */
        & > .field:not(:first-child)
            margin-left: 0.75rem

        /* Give margin on right if not last field */
        & > .field:not(:last-child)
            margin-right: 0.75rem

        /* Allow fields to grow to fill up all horizontal space */
        & > .field
            flex: 1 1 0

        /* Remove bottom margin if grouped fields are the last in the form */
        &:last-child > .field
            margin-bottom: 0

    /* If grouped fields aren't the last in the form, give them bottom margin */
    .field-group:not(:last-child) > .field
        margin-bottom: 0.75rem

/* Mobile */
@media screen and (max-width: $sm-screen)
    .field-group:not(:last-child) > .field
        margin-bottom: 0.75rem