@charset "utf-8"

@import './variables'

// Import a Google Font
@import url('https://fonts.cdnfonts.com/css/julius-sans-one')
@import url('https://fonts.googleapis.com/css?family=Open+Sans')
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700')

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass'
@import '../node_modules/bulma/sass/base/_all.sass'
@import '../node_modules/bulma/sass/elements/box.sass'
@import '../node_modules/bulma/sass/elements/button.sass'
@import '../node_modules/bulma/sass/elements/container.sass'
@import '../node_modules/bulma/sass/elements/content.sass'
@import '../node_modules/bulma/sass/elements/icon.sass'
@import '../node_modules/bulma/sass/elements/title.sass'
@import '../node_modules/bulma/sass/form/_all.sass'
@import '../node_modules/bulma/sass/grid/columns.sass'
@import '../node_modules/bulma/sass/components/card.sass'
@import '../node_modules/bulma/sass/components/navbar.sass'
@import '../node_modules/bulma/sass/helpers/typography.sass'
@import '../node_modules/bulma/sass/helpers/visibility.sass'
@import '../node_modules/bulma/sass/layout/hero.sass'
@import '../node_modules/bulma/sass/layout/section.sass'
@import '../node_modules/bulma/sass/utilities/mixins.sass'
    
// Common components
@import './components/shared-components/styles/form'

// Fonts and icons
@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss'
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid.scss'
@import '../node_modules/@fortawesome/fontawesome-free/scss/brands.scss'

body
    height: 100vh
    padding-bottom: 24px // Footer height
    margin: 0
    font-family: 'Open Sans'
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale