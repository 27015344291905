@import '../../../variables'
.card
    overflow: hidden
    .card-header
        cursor: pointer

    .icon
        margin-right: 10px
        color: $grey-darker

    .card-content
        max-height: 750px
        transition: max-height 0.35s ease-in
        overflow: hidden
        position: relative
        &.collapsed
            max-height: 0
            transition: max-height 0.35s ease-out
            transition: height 0.35s ease-out
            overflow: hidden
            position: relative
            // height: 0
            padding: 0
            ul
                margin: 0
