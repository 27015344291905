// Set your brand colors
$purple: #8a4d76
$gold: #cda017
$gold-light: #f5c000
$charcoal: #343024
$brown: #757763
$beige-light: #d0d1cd
$beige-lighter: #eff0eb
$white: #fff

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif
$grey-dark: #828582
$grey-darker: #545654
$grey-light: $beige-light
$primary: $white
$secondary: $gold
$tertiary: $charcoal
$link: $gold
$widescreen-enabled: false
$fullhd-enabled: false
$danger: #e51A29
$family-primary: 'Open Sans'

// Media breakpoints
$sm-screen: 600px
$md-screen: 768px
$lg-screen: 992px
$xl-screen: 1200px

// Update some of Bulma's component variables
$body-background-color: $tertiary
$card-header-background-color: $secondary
$content-heading-color: $secondary
$control-border-width: 2px
$input-border-color: transparent
$label-color: $primary
$link-hover: $gold-light
$navbar-height: 3.25rem
$input-shadow: none
$title-color: $secondary
// $navbar-background-color: $charcoal

// Font awesome font path
$fa-font-path: '../webfonts'
