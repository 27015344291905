@import '../../variables'

.pf-properties
    .control
        display: flex
        justify-content: center
        align-items: center
    .select
        margin-bottom: 10px

    .properties-message
        margin-top: 10%
        display: flex
        justify-content: center
        align-items: center
        text-align: center
        .title
            color: $primary

    .loading-wrapper
        height: 60vh