@import '../../../variables'

.navbar
    width: 100vw
    font-family: 'Julius Sans One', sans-serif

    .navbar-brand
        img
            max-height: 3rem
            
.navbar-menu.is-active
    animation: navAnim .3s ease-in-out

.navbar-start
    flex-grow: 1

@keyframes navAnim
    0%
        display: none
        opacity: 0
        height: 0

    1%
        display: block
        opacity: 0

    100%
        opacity: 1
        height: 216px

@media screen and (min-width: $lg-screen)
    .navbar
        margin-right: 15px