@import '../../variables'

.pf-home
    display: flex
    height: calc(100vh - #{$navbar-height})
    flex-direction: column
    align-items: center
    justify-content: center
    background-color: $white
    color: $grey-dark

    .pf-tagline
        display: flex
        justify-content: center
        align-items: center
        font-size: 20px
        
    .logo-container
        display: flex
        justify-content: center
        align-items: center

    .logo
        width: 600px

@media screen and (max-width: $sm-screen)
    .pf-tagline
        font-size: 14px
